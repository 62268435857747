<template>
  <div class="main">
    <div class="wxapp">
      <span class="tip">小程序/渠道:</span>
      <div class="content">
        <a-checkable-tag :checked="miniId == 0" @change="changeMini(0)">全部</a-checkable-tag>
        <a-checkable-tag v-for="item in wxMiniData" :key="item.appId" :checked="item.appId == miniId" @change="changeMini(item.appId)">{{item.back}}</a-checkable-tag>
      </div>
    </div>
    <a-space direction="vertical" size="middle">
      <a-row>
        <a-col :span="24">
          <a-space>
            <span>类型:</span>
            <a-checkable-tag :checked="typeId == 0" @change="changeType(0)">全部</a-checkable-tag>
            <a-checkable-tag :checked="typeId == 1" @change="changeType(1)">在线起名</a-checkable-tag>
            <a-checkable-tag :checked="typeId == 2" @change="changeType(2)">姓名评分</a-checkable-tag>
          </a-space>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-space>
            <span>状态:</span>
            <a-checkable-tag :checked="statusId == 0" @change="changeStatus(0)">全部</a-checkable-tag>
            <a-checkable-tag :checked="statusId == 1" @change="changeStatus(1)">未付款</a-checkable-tag>
            <a-checkable-tag :checked="statusId == 2" @change="changeStatus(2)">已付款</a-checkable-tag>
          </a-space>
        </a-col>
      </a-row>
    </a-space>
    <a-divider dashed/>
    <a-row style="margin-bottom:24px">
      <a-col :span="24">
        <a-space>
          <span>搜索:</span>
          <a-input-group compact>
            <a-input-search style="width: 260px;" placeholder="输入 订单号/手机号/用户id 搜索" allowClear v-model="keyword" @search="getListData(1)"/>
            <a-select :default-value="searchId" @change="changeSearch">
              <a-select-option :value="1">订单号</a-select-option>
              <a-select-option :value="2">手机号</a-select-option>
              <a-select-option :value="3">用户id</a-select-option>
            </a-select>
          </a-input-group>
          <a-button type="primary" :disabled="listLoading" :loading="listLoading" @click="getListData(1)">搜索</a-button>
        </a-space>
      </a-col>
    </a-row>
    <!-- 菜单列表 -->
    <a-spin tip="Loading..." :spinning="listLoading">
      <a-table :columns="tableTitle" :pagination="listPagination" :data-source="data" size="middle" :rowKey="record=>record.id">
        <template slot="type" slot-scope="text, record">
          <a-tag v-if="record.type == 1" color="#f50">在线起名</a-tag>
          <a-tag v-else-if="record.type == 2" color="#2db7f5">姓名评分</a-tag>
        </template>
        <template slot="money" slot-scope="text, record">
          <a-statistic :value="record.money" :precision="2" :value-style="{ color: '#1890ff' }">
            <template #suffix>
              <span>元</span>
            </template>
          </a-statistic>
        </template>
        <template slot="pay" slot-scope="text, record">
          <a-badge v-if="record.status == 0" status="warning" text="未支付" />
          <a-badge v-else-if="record.status == 1" status="processing" text="已支付" />
        </template>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm v-if="record.status == 0" title="请确认您已收到该订单的付款，是否继续?" @confirm="doneOrder(record.id)">
            <a-button size="small" icon="check">补单</a-button>
          </a-popconfirm>
          <a v-else>无</a>
          <a-divider type="vertical" />
          <a-popover title="订单信息" placement="topRight">
            <template slot="content">
              <p>姓名：{{record.name}}</p>
              <p>性别：{{record.sex}}</p>
              <p>生日：{{record.date}}</p>
            </template>
            <a-button size="small" icon="info">信息</a-button>
          </a-popover>
        </span>
      </a-table>
    </a-spin>
    <!-- 菜单列表 End -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableTitle: [
        { title: "订单号", dataIndex: "order_id", key: "order_id" },
        { title: "小程序/渠道", dataIndex: "back", key: "back" },
        { title: "类型", dataIndex: "type", key: "type", scopedSlots: { customRender: "type" }},
        { title: "用户id", dataIndex: "user_id", key: "user_id" },
        { title: "手机号", dataIndex: "phone", key: "phone" },
        { title: "支付时间", dataIndex: "time", key: "time", width: '160px'},
        { title: "金额(元)", dataIndex: "money", key: "money", scopedSlots: { customRender: "money" }},
        { title: "支付", dataIndex: "pay", key: "pay", scopedSlots: { customRender: "pay" }},
        { title: "操作", key: "action", scopedSlots: { customRender: "action" },width:"200px"}
      ],
      keyword: '',
      data: [],
      listLoading: false, //列表loadding
      listPagination: {
        onChange: page => {
          this.listPage = page;
          this.getListData(page);
        },
        showQuickJumper: true,
        total: 0,
        current: 1,
        pageSize: 10
      },
      statusId: 0,
      typeId: 0,
      miniId: 0,
      wxMiniData: [],
      searchId: 1,
    };
  },
  mounted() {
    this.getMiniList()
    this.getListData(1);
  },
  methods: {
    getMiniList(){
      this.$http
        .get("/service/admin/getMiniList")
        .then(res => {
          //请求成功后的处理函数
          this.wxMiniData = res.data.result
        })
        .catch(() => {
          //请求失败后的处理函数
        });
    },
    getListData(pages) {
      this.listLoading = true;
      this.$http
        .get("/service/order/payOrder",{params: {page: pages, app_id: this.miniId, type: this.typeId, status: this.statusId, search: this.searchId, keywords: this.keyword}})
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.listPagination.current = pages
          this.data = res.data.result.data
          this.listPagination.total = res.data.result.count
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    doneOrder(id) {
      this.listLoading = true;
      this.$http
        .get("/service/order/doneOrder",{params: {id: id}})
        .then(res => {
          //请求成功后的处理函数
          //请求成功后的处理函数
          this.postLoading = false;
          this.$notification["success"]({
            message: "系统提示",
            description: res.data.msg,
            duration: 2.5,
            key: 2
          });
          this.getListData(this.listPagination.current)
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    //切换版块
    changeType(val){
      if(this.typeId === val)return false
      this.typeId = val
      this.getListData(1)
    },
    //切换版块
    changeStatus(val){
      if(this.statusId === val)return false
      this.statusId = val
      this.getListData(1)
    },
    changeMini(val){
      if(this.miniId === val)return false
      this.miniId = val
      this.getListData(1)
    },
    changeSearch(val){
      this.searchId = val
    }
  }
};
</script>
<style lang="scss" scoped>
  .wxapp{
    display: flex;
    flex-direction: row;
    > .tip{
      margin-right: 8px;
    }
    > .content{
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > .ant-tag{
        margin-bottom: 4px;
      }
    }
  }
</style>